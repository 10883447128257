import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";

const Conditions = () => {
  useEffect(() => {
    document.title = 'Conditions générales d\'utilisation - Meal Prep Minceur Dr Cohen';
  }, []);
  
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Conditions générales d'utilisation</strong>
              </h1>

              <p>
                Ce contrat entre le fournisseur de service et éditeur, Anxa Ltd, et vous, l'utilisateur, définit la
                façon dont vous allez pouvoir utiliser les sites et services de Anxa Ltd. ainsi que leur équivalent chez
                les partenaires qui les distribuent.
              </p>
              <p>
                Merci de lire attentivement le contenu de ce document avant d'accéder ou d'utiliser le site et les
                services d'Anxa Ltd. En accédant ou en utilisant les sites et services d'Anxa Ltd., vous acceptez les
                termes du contrat et les conditions générales d'utilisation décrites. Si vous ne souhaitez pas être
                soumis à cette obligation de respect du contrat, vous n'êtes pas autorisé à accéder ou à utiliser le
                site et les services d'Anxa Ltd. dans ce cas, nous vous prions de ne pas poursuivre votre visite de ce
                site et de ces services.
              </p>
              <p>
                Anxa Ltd. pourra modifier ce contrat à n'importe quel moment et les modifications éventuelles seront
                immédiatement effectives.
              </p>

              <ul style={{ listStyle: "decimal" }}>
                <li>
                  {" "}
                  <a href="#an-1">Acceptation des conditions générales d'utilisation</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-2">Description des services</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-3">Paiement et sécurité</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-4">Garantie Satisfait ou remboursé</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-5">Communication des données personnelles et protection de la vie privée</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-6">Engagements de l'utilisateur</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-7">Indemnisation</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-8">Garanties</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-9">Limitation de responsabilité</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-10">Droit de propriété</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-11">Modification des services et des conditions générales</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-12">Notification</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-13">Conditions de participation</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-14">Mentions légales relatives à la publicité</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-15">Loi applicable et juridiction competente</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-16">Au-delà des obligations légales</a>
                </li>
                <li>
                  {" "}
                  <a href="#an-17">Divers</a>
                </li>
              </ul>
              <br />
              <hr />
              <br />
              <div className="cgv-sections" id="an-1">
                <h4 className="salmon">1 - ACCEPTATION DES CONDITIONS GÉNÉRALES D'UTILISATION</h4>
                <p>
                  L'utilisateur s'engage à accepter le fait que les forums soient consultables par des tierces
                  personnes.
                </p>
                <p>
                  Les présentes conditions générales régissent les modalités d'utilisation du service{" "}
                  <a href="https://mealprep.jeanmichelcohen.fr/">https://mealprep.jeanmichelcohen.fr/</a>{" "}
                  exploité par Anxa Ltd. Le fait de choisir de consulter les pages de ces services constitue une
                  acceptation des conditions générales d'utilisation. Anxa Ltd. s'engage à fournir ces services, sous
                  réserve de l'engagement réciproque de l'utilisateur à respecter les présentes conditions.
                </p>
                <p>
                  Anxa Ltd. est libre de modifier à tout moment le contenu des conditions générales et il est conseillé
                  aux utilisateurs de s'y référer régulièrement.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-2">
                <h4 className="salmon">2 - DESCRIPTION DES SERVICES</h4>
                <p>Anxa Ltd. fournit à ses utilisateurs des services désignés comme suit :</p>
                <ul>
                  <li>Des programmes de coaching en ligne, des plans de repas et des listes de courses.</li>
                  <li>
                    Des outils communautaires permettant aux utilisateurs de communiquer entre eux afin de se motiver.
                    (forums et blogs animés par une équipe de diététiciennes).
                  </li>
                  <li>La possibilité de poser quatre questions par mois à notre équipe de diététiciennes.</li>
                  <li>
                    L'accès à un numéro de téléphone pour obtenir des informations complémentaires sur le programme
                    avant ou après l'inscription.
                  </li>
                  <li>
                    Les programmes de coaching en ligne incluent un appel téléphonique mensuel par un conseiller mandaté
                    par le fournisseur de service. Cet appel vise à assurer le contrôle de la qualité de
                    l'accompagnement fourni à l'utilisateur et, plus généralement, sa satisfaction dans le suivi du
                    programme. Si, pour des raisons personnelles, l'utilisateur ne souhaite pas bénéficier de ce service
                    d'accompagnement, il peut le préciser au service-client par E-mail ou par téléphone (lien
                    "Rappelez-moi" au bas de cette page).
                  </li>
                </ul>

                <p>
                  Cette description des services proposés n'est ni exhaustive ni définitive, et Anxa Ltd. se réserve le
                  droit de supprimer, de modifier ou d'ajouter des services ou de nouvelles caractéristiques, dans le
                  seul but d'améliorer la qualité des produits proposés.
                </p>
                <p>
                  L'ensemble des services disponibles sont payants et l'utilisateur accepte les conditions de livraison
                  du service après son paiement en ligne.
                </p>
                <p>
                  Afin de bénéficier des services, l'utilisateur doit disposer d'un accès à Internet, soit directement,
                  soit par l'intermédiaire de systèmes ayant un accès à du contenu diffusé sur Internet. Les frais
                  correspondants à cette connexion sont à la charge exclusive de l'utilisateur. De plus, il appartient à
                  l'utilisateur de se munir du matériel nécessaire, et notamment d'un ordinateur, d'un modem ou de tout
                  autre outil de connexion.
                </p>
              </div>
              <br />
              <div className="cgv-sections" id="an-3">
                <h4 className="salmon">3 - PAIEMENT ET SÉCURITÉ</h4>
                <p>
                  Les services de Coaching Anxa ne sont disponibles qu'après paiement par l'utilisateur. Plusieurs modes
                  de paiement en ligne sont proposés et l'utilisateur accepte les conditions financières et de
                  facturation en procédant à l'achat d'un service. Anxa Ltd. se réserve le droit, à tout moment et sans
                  en communiquer la raison aux utilisateurs, de modifier le prix de ses services.
                </p>
                <p>
                  Toutes ces solutions offrent les meilleures conditions de sécurité et de confidentialité. Anxa Ltd. se
                  réserve le droit à tout moment et sans en communiquer la raison aux utilisateurs, de supprimer,
                  définitivement ou temporairement, de suspendre ou de modifier, l'une ou l'autre des solutions de
                  paiement proposées, ceci afin d'améliorer la qualitéé de ses services.
                </p>

                <p>
                  <strong>Tarif des accès et abonnements :</strong>
                </p>
                <p>
                  Ce service est accessible uniquement par abonnement au prix de 4,99 euro par mois. Des réductions
                  peuvent s'appliquer aux clients qui choisissent de s'engager pour une durée minimale de trois ou six
                  mois.
                </p>
                <p>
                  Pour vous permettre de bénéficier de ces tarifs réduits, les abonnements sont renouvelés
                  automatiquement tous les mois.
                </p>
                <p>
                  Vous pouvez bien sûr à tout moment annuler le renouvellement automatique de votre accès en écrivant au
                  support.
                </p>

                <ul>
                  <li>À la fin de votre accès 1 mois, vous serez prélevé de 4,99 € par mois.</li>
                  <li>À la fin de votre accès 3 mois, vous serez prélevé de 3,33 € par mois.</li>
                </ul>
              </div>

              <br />
              <div className="cgv-sections" id="an-4">
                <h4 className="salmon">4 - GARANTIE SATISFAIT OU REMBOURSÉ</h4>
                <p>
                  Conformément aux dispositions légales, vous disposez d'un délai de rétractation de 14 jours à compter
                  de votre achat pour déposer une demande d'annulation de votre commande.
                </p>
                <p>
                  Si vous n'êtes pas satisfait dans les 14 jours suivant votre achat, vous pouvez demander le
                  remboursement.
                </p>
                <p>
                  Pour faire votre demande merci de nous appeler au 04 11 88 01 12 (ouvert du lundi au vendredi de 9H00
                  à 18h)
                </p>

                <p>
                  Vous pouvez également nous envoyer votre demande à l'adresse suivante : <br />
                  <br />
                  Aujourdhui.com
                  <br />
                  Buro Club
                  <br />
                  543 Rue de la Castelle
                  <br />
                  34070 Montpellier
                  <br />
                  Tel : <a href="tel:+33411880112">+33 4 11 88 01 12</a>
                  <br />
                </p>

                <p>
                  N'oubliez pas de mentionner dans votre courrier l'adresse email utilisée pour procéder à votre
                  inscription. Nous prendrons en considération le cachet de "La Poste" comme date de demande de
                  remboursement.
                </p>

                <p>
                  Si vous êtes à l’étranger vous pouvez nous envoyer toute demande en remplissant le formulaire
                  électronique.
                </p>
              </div>
              <br />
              <div className="cgv-sections" id="an-5">
                <h4 className="salmon">5 - COMMUNICATION DES DONNÉES PERSONNELLES ET PROTECTION DE LA VIE PRIVÉE</h4>
                <p>Anxa Ltd. peut être amené à collecter des données sur ses utilisateurs.</p>
                <p>
                  Les données personnelles collectées concernant l'utilisateur, via l’utilisation de leurs sites sont
                  notamment son nom, prénom, adresse électronique ou adresse postale, numéro de téléphone, âge, poids,
                  sexe, taille, adresse IP, historique d’achats, de navigation.
                </p>
                <p>
                  Ces informations sont nécessaires afin de proposer à l’utilisateur et d’améliorer continuellement nos
                  produits et services : création du compte personnel, recommandations et personnalisation des services,
                  communication avec l’utilisateur, commande, livraison des produits et fourniture des services,
                  inscription aux lettres d’informations d’Anxa Ltd., proposition de participation à des évènements
                  organisés par Anxa Ltd., et toutes autres finalités pour lesquelles nous recueillons le consentement
                  de l’utilisateur.
                </p>
                <p>
                  L'utilisateur peut choisir de ne pas nous communiquer certaines informations, cette décision risque
                  néanmoins de priver l'utilisateur de nombreux services qu’Anxa Ltd. propose.
                </p>
                <p>Anxa Ltd. n’est pas responsable des erreurs de saisie et des conséquences qui en découlent.</p>
                <p>
                  Le destinataire des données à caractère personnel collectées est Anxa Ltd. en sa qualité de
                  responsable du traitement. L'utilisateur dispose d’un droit d’accès, de rectification, d’effacement et
                  à la portabilité de ses données. L'utilisateur peut également demander la limitation du traitement des
                  données le concernant ou s'opposer à ce traitement. L'utilisateur est en droit de communiquer ses
                  directives relatives au sort des données personnelles le concernant en cas de décès. Pour exercer ces
                  droits, l’utilisateur peut contacter Anxa Ltd. par courrier électronique à{" "}
                  <a href="mailto:support@anxa.com">support@anxa.com</a> accompagné d’une copie d’un titre d’identité en
                  cours de validité. Les données personnelles sont conservées pendant un délai de trois ans à compter de
                  leur collecte par Anxa Ltd..
                </p>

                <p>
                  Si l'utilisateur ne souhaite pas faire l’objet de prospection commerciale par voie téléphonique, il
                  peut s’inscrire sur une liste d’opposition au démarchage téléphonique sur le site{" "}
                  <a href="https://www.bloctel.gouv.fr">www.bloctel.gouv.fr</a>.
                </p>
                <p>
                  Les lettres d’informations sont susceptibles de contenir des cookies destinés à proposer des offres
                  adaptées à aux besoins et centres d’intérêt de l’utilisateur et à réaliser des statistiques de
                  visites. Les cookies déposés sur le terminal de l’utilisateur sont conservés pendant une durée de 13
                  mois à compter de leur 1er dépôt.
                </p>
                <p>
                  Les données personnelles que l'utilisateur est amené à communiquer à Anxa Ltd., sur leurs sites, sont
                  traitées dans le cadre des principes de confidentialité et de protection de la vie privée, et dans le
                  respect des réglementations en vigueur. Ces principes sont exposés et détaillés dans la Charte Sur la
                  Vie Privée.
                </p>
                <p>
                  En cas de non-respect de ses droits, l'utilisateur peut introduire une réclamation auprès de la CNIL.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-6">
                <h4 className="salmon">6 - ENGAGEMENTS DE L'UTILISATEUR</h4>
                <p>
                  L'utilisateur s'engage à ne pas revendre et à ne pas utiliser les services d'Anxa Ltd. à des fins
                  commerciales.
                </p>
                <p>
                  L'utilisateur s'engage à ne pas utiliser les sites et service d'Anxa Ltd. à des fins illégales,
                  notamment à ne pas transmettre des messages contraires à l'ordre public et aux bonnes moeurs, et des
                  propos diffamatoires, des éléments attentatoires à la vie privée de tiers ; à ne pas transmettre
                  d'informations qui pourraient nuire de manière quelconque aux droits d'Anxa Ltd. et des autres
                  utilisateurs. L'utilisation de cette méthode est réservée aux personnes en bonne santé physique et
                  psychologique. En cas de doute, l'utilisateur est invité à consulter son médecin traitant.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-7">
                <h4 className="salmon">7 - INDEMNISATION</h4>
                <p>
                  L'utilisateur s'engage à garantir et indemniser Anxa Ltd., ainsi que ses représentants, salariés,
                  partenaires, contre tout dommage, plainte ou demande émanant de tiers consécutif à l'envoi, la
                  diffusion ou la transmission de contenu issu des sites d'Anxa, à l'utilisation des services proposés,
                  à la violation des présentes conditions d'utilisation ou des droits d'autrui.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-8">
                <h4 className="salmon">8 - GARANTIES</h4>
                <p>
                  <strong>L'utilisateur reconnaît et accepte que :</strong>
                </p>
                <p>
                  Les services sont livrés en l'état et accessibles en fonction des disponibilités. Anxa Ltd. ne fournit
                  aucune garantie quant à la durée, l'effacement, la mauvaise livraison ou le défaut d'enregistrement de
                  la communication d'un utilisateur.
                </p>
                <p>
                  Anxa Ltd. ne garantit pas que le service répondra parfaitement aux attentes de l'utilisateur, et
                  notamment que les résultats qui peuvent être obtenus seront exacts. Anxa Ltd. ne garantit pas que le
                  service sera ininterrompu, sur ou dépourvu de toute erreur.
                </p>
                <p>
                  L'utilisation des services est au seul risque de l'utilisateur qui sera responsable pour tout dommage
                  causé à son ordinateur ou toute perte de données qui résulteraient du téléchargement ou de la
                  consultation du contenu des services.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-9">
                <h4 className="salmon">9 - LIMITATION DE RESPONSABILITÉ</h4>
                <p>
                  Le site{" "}
                  <a href="https://mealprep.jeanmichelcohen.fr/">https://mealprep.jeanmichelcohen.fr//</a> ne
                  peut en aucun cas se substituer à une consultation médicale dans le domaine de la diététique. C'est un
                  site d'informations nutritionnelles, diététiques et du bien être de la personne. En aucun cas les
                  informations et services proposés dans ce site ne peuvent permettre d'établir un diagnostic médical
                  concernant votre poids ou votre santé. Les informations données dans ce site ne remplaceront jamais la
                  consultation d'un médecin généraliste, spécialiste ou de tout autre professionnel de santé. De plus,
                  ces recommandations peuvent ne pas être adaptées aux personnes qui suivent un régime alimentaire
                  particulier, prescrit par un médecin, dans le cadre d'une pathologie spécifique (cas des patients
                  présentant un diabète, une intolérance au gluten ou une maladie coeliaque, une allergie alimentaire,
                  certaines maladies rénales) ou d'une grossesse.
                </p>
                <p>
                  Vous ne devez pas mettre en oeuvre les informations disponibles sur les sites d'Anxa Ltd. pour la
                  formulation d'un diagnostic, la détermination d'un traitement ou la prise et/ou la cessation de la
                  prise de médicament sans consulter préalablement un médecin généraliste ou spécialiste. Vous
                  reconnaissez que les informations qui sont mises à votre disposition ne sont ni complètes, ni
                  exhaustives et que ces informations ne traitent pas de l'intégralité des différents symptômes,
                  médicaments et traitements appropriés aux pathologies et différents maux quotidiens qui vous
                  intéressent. Par ailleurs,{" "}
                  <a href="https://mealprep.jeanmichelcohen.fr/">https://mealprep.jeanmichelcohen.fr/</a> ne
                  garantit absolument pas un quelconque résultat à la suite de l'application par l'internaute des
                  informations et services proposés, notamment en ce qui concerne la perte de poids. De plus, Anxa Ltd.
                  Ne pourra être tenu pour responsable :
                </p>

                <ul>
                  <li>
                    de tout dommage direct ou indirect, de quelques nature que ce soit ; ceci comprend notamment les
                    pertes de profits, résultant de l'utilisation ou l'impossibilité d'utiliser ses services,
                  </li>
                  <li>
                    de coûts consécutifs à l'acquisition de biens ou de services de substitution résultant de
                    l'utilisation du services,
                  </li>
                  <li>
                    de tout dommage direct ou indirect, de quelque nature que ce soit, suite à une déclaration d'un
                    tiers sur le service.
                  </li>
                </ul>
                <p>
                  En conséquence, vous reconnaissez que la responsabilité d'Anxa Ltd. ne pourra être mise en cause au
                  niveau de l'information et des services proposés par ce site, et vous acceptez que l'utilisation de
                  ces informations et services s'effectue sous votre seule et entière responsabilité, contrôle et
                  direction.
                </p>
                <p>Services interactifs (chats, blogs, forums, etc.)</p>
                <p>
                  L'ensemble des dispositions de la présente charte s'applique lors de toute participation aux services
                  interactifs proposés sur les sites d'Anxa Ltd. Respecter l'ensemble des dispositions légales et
                  réglementaires en vigueur est donc impératif.
                </p>
                <p>
                  A ce titre, seul l'utilisateur peut être considéré comme responsable des informations, messages,
                  images et, d'une manière générale, de tout contenu diffusé via les services interactifs tels que
                  notamment chat, blog et forum.
                </p>
                <p>
                  L'utilisateur accepte également qu'Anxa Ltd. prenne l'initiative, sans mise en demeure préalable, de
                  supprimer, en totalité ou partiellement, tout contenu qu'il diffuserait ou qui lui serait adressé via
                  les services interactifs, si ce contenu est susceptible de porter atteinte aux lois et règlements en
                  vigueur ou aux bonnes moeurs.
                </p>
                <p>
                  L'utilisateur reconnaît être seul responsable de l'utilisation qui pourra être faite du contenu, de
                  quelque nature que ce soit, qu'il diffuserait, et que Anxa Ltd. ne procède pas au contrôle, ni ne
                  garantit l'exactitude, la légalité, la qualité ou la fiabilité des contenus de quelque nature qu'ils
                  soient, diffusés via les services interactifs proposés sur les sites d'Anxa Ltd.
                </p>
                <p>
                  L'utilisateur est seul responsable des prises de contact mises en oeuvre via les services interactifs
                  des sites d'Anxa Ltd. Sur ses services interactifs, Anxa Ltd. n'est pas en mesure d'offrir une
                  garantie totale et permanente quant au respect :
                </p>
                <p>
                  Des règles d'ordre public, telles que par exemple la réglementation en matière de contenu
                  pornographique, raciste ou illicite qui serait susceptible de porter atteinte à l'intégrité ou à la
                  sensibilité d'un autre internaute ou à l'image de marque d'ANXA Ltd. par l'intermédiaire de messages,
                  textes ou images provocants.
                </p>
                <p>
                  Des règles relatives au respect de la vie privée des personnes, internautes connectés sur les sites
                  d'Anxa Ltd. ou tiers. A ce titre il s'engage à s'abstenir de diffuser au sein des services interactifs
                  ( ) proposés des messages à caractère injurieux, insultant, dénigrant, dégradant ou n'ayant aucun lien
                  avec les questions abordées.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-10">
                <h4 className="salmon">10 - DROIT DE PROPRIÉTÉ</h4>
                <p>
                  L'utilisateur reconnaît et accepte que le contenu des services, en ce qui inclus, sans que cette liste
                  ne soit limitative, les textes, logiciels, musiques, sons, photographies, vidéos, dessins ou autres
                  matériels contenus dans les services ou dans les publicités distribuées via e-mail, information
                  commerciale produite et présentée par Anxa Ltd., ses fournisseurs ou annonceurs, sont protégés par le
                  droit de la propriété intellectuelle, le droit des marques, le droit des brevets ou tout autre droit
                  reconnu par la législation en vigueur. L'utilisateur n'est pas autorisé à copier, utiliser,
                  reproduire, distribuer ou créer des oeuvres dérivées à partir du contenu des services d'Anxa Ltd.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-11">
                <h4 className="salmon">11 - MODIFICATION DES SERVICES ET DES CONDITIONS GÉNÉRALES</h4>
                <p>
                  Anxa se réserve le droit, à tout moment, de modifier, interrompre temporairement ou définitivement,
                  certains ou la totalité de leurs services.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-12">
                <h4 className="salmon">12 - NOTIFICATIONS</h4>
                <p>
                  Toutes les notifications de la part des utilisateurs au sujet de ces présentes conditions
                  d'utilisation devront se faire par écrit et pourront être envoyées par courrier postal à l'adresse
                  suivante:
                </p>
                <p>
                  Représentant fiscal <br />
                  Aujourdhui.com <br />
                  Buro Club <br />
                  543 Rue de la Castelle <br />
                  34070 Montpellier <br />
                  Tel : +33 4 11 88 01 12 <br />
                </p>

                <p>
                  Anxa Limited <br />
                  Suite 1601-1603 <br />
                  Kinwick Center <br />
                  32 Hollywood Road, <br />
                  Central, Hong Kong <br />
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-13">
                <h4 className="salmon">13 - CONDITIONS DE PARTICIPATION</h4>
                <p>
                  De façon occasionnelle, Anxa et ses partenaires pourront être amenés à proposer des concours et des
                  promotions sur leurs sites.
                </p>
                <p>
                  Ces promotions sont ouvertes à tous, à l'exclusion des employés de Anxa, Ltd, ou de toutes les
                  personnes ayant participé à l'élaboration directe ou indirecte du jeu de même que leur famille. Dans
                  certains cas, la participation pourra être limitée aux utilisateurs ayant atteint l'âge de dix-huit
                  (18) ans (selon le type de promotion).
                </p>
                <p>
                  Pour chaque utilisateur ayant complété un test et indiqué une adresse e-mail valide, dans le respect
                  des délais accordés avant le tirage, il sera accordé 1 participation au tirage au sort.
                </p>
                <p>
                  Il n'y a pas de limite au nombre de participations autorisées pour chaque utilisateur. Toute
                  participation doit être reçue et validée pendant la période promotionnelle indiquée (comme mentionné
                  sur les détails de la promotion).
                </p>
                <p>
                  Le tirage au sort se fera électroniquement. Une fois le gagnant désigné, aucune contestation d'aucune
                  sorte ne sera acceptée. La décision du jury est sans appel.
                </p>
                <p>
                  Le prix attribué au gagnant sera délivré dans les plus brefs délais. Le gagnant accepte de recevoir
                  son prix tel qu'il a été annoncé. Aucune contrepartie ou équivalent financier des gains ne pourra être
                  sollicité par le(s) gagnant(s).
                </p>
                <p>
                  Le gagnant sera informé de son gain par e-mail. Le(s) gagnant(s) autorise(nt) expressément et
                  gracieusement l'utilisation et la diffusion de ses (leurs) nom et détails à l'occasion de toute
                  campagne publicitaire ou promotionnelle sur Internet ou par publication dans la presse sur tout
                  support.
                </p>
                <p>
                  Le simple fait de participer au tirage au sort entraîne l'acceptation pure et simple du présent
                  règlement. En cas de non acceptation des présentes conditions, l'utilisateur est invité à contacter
                  Anxa Ltd. en utilisant le lien Contact prévu à cet effet sur le site pour solliciter son retrait de la
                  liste des participants.
                </p>
                <p>
                  La participation au tirage au sort et l'utilisation des services de ce site sont soumises au
                  Conditions Générales d'Utilisation et à la Charte sur le respect de la vie privée, clairement indiqués
                  au bas de chaque page sur ce site.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-14">
                <h4 className="salmon">14 - MENTIONS LÉGALES RELATIVES À LA PUBLICITÉ</h4>
                <p>
                  Le site{" "}
                  <a href="https://mealprep.jeanmichelcohen.fr/">https://mealprep.jeanmichelcohen.fr/</a> vous
                  propose de bénéficier de contenus publicitaires ciblés en fonction de vos comportements de navigation
                  sur le site{" "}
                  <a href="https://mealprep.jeanmichelcohen.fr/">https://mealprep.jeanmichelcohen.fr/</a>. Afin
                  que vous puissiez bénéficier de ce service, il est nécessaire de placer un cookie " orangeads " sur
                  votre terminal de connexion internet lors de votre navigation sur le site{" "}
                  <a href="https://mealprep.jeanmichelcohen.fr/">https://mealprep.jeanmichelcohen.fr/</a>.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-15">
                <h4 className="salmon">15 - LOI APPLICABLE ET JURIDICTION COMPETENTE</h4>
                <p>
                  Pour les acheteurs situés en France, le présent contrat sera soumis à la loi Honkongaise et les règles
                  légales de compétence territoriale ou d’attribution seront applicables. Pour les acheteurs situés dans
                  l'Union Européenne, le présent contrat sera soumis à la loi Honkongaise, sauf si la conclusion du
                  contrat a été précédée d’une publicité ou d’une proposition spécialement faite par ANXA Ltd. dans le
                  pays du consommateur. Le tribunal compétent sera déterminé selon les dispositions du Règlement
                  communautaire n° 44/2001 du 22 décembre 2000.
                </p>
                <p>
                  Pour les consommateurs situés dans d’autres pays : sauf dispositions légales impératives contraires,
                  le présent contrat sera régi par la loi hongkongaise et tout litige né du présent contrat relèvera de
                  la compétence exclusive des tribunaux Honkongais.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-16">
                <h4 className="salmon">16 - AU-DELÀ DES OBLIGATIONS LÉGALES</h4>
                <p>Au-delà des obligations légales, ANXA Ltd s'engage sur les règles déontologiques suivantes :</p>

                <p>
                  <strong>Les cookies</strong> <br />
                  Des fichiers de cookies sont inscrits dans la mémoire de votre navigateur. Leur durée de vie est
                  permanente. Ils contiennent un identifiant unique par navigateur. Ils constituent l'historique des
                  visites sur notre site et sur chacune de ses pages. Vous avez toujours la possibilité de paramétrer
                  votre navigateur afin de refuser ces cookies sans altérer pour autant votre navigation sur notre site.
                </p>

                <p>
                  <strong>Utilisation des e-mails</strong>
                  <br />
                  Dès lors que vous passez une commande sur notre site vous nous communiquez vos coordonnées e-mail qui
                  sont utilisées pour le suivi de votre commande. Ces coordonnées sont également utilisées pour vous
                  envoyer nos bulletins d'informations sur nos produits. Si vous ne souhaitez plus recevoir
                  d'informations de la part de ANXA Ltd, nous vous donnons toujours la possibilité par retour de mail ou
                  en cliquant sur le lien présent dans les e-mails, de vous désabonner de cette liste de diffusion.
                </p>
              </div>

              <br />
              <div className="cgv-sections" id="an-17">
                <h4 className="salmon">17 - DIVERS</h4>
                <p>
                  Les présentes Conditions Générales d'Utilisation constituent l'intégralité de l'accord passé entre
                  Anxa Ltd. et l'utilisateur pour ce qui concerne l'utilisation des services, et se substituent à tout
                  accord éventuellement intervenu antérieurement entre l'utilisateur et Anxa Ltd.
                </p>
              </div>

              <br />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Conditions;
